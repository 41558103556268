export default function PrivacyPolicy() {
  return (
    <>
      <section className="pt-28 pb-10">
        <div className="overlay flex items-center justify-center text-center">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <div className="privacy__policy px-5 text-gray-800 bg-gray-50 2xl:px-0 p-5">
        <article className="xl:max-w-5xl xl:mx-auto">
          <h4>Privacy Policy</h4>
          <p>
            Trading Technologies Limited (“we”, “us”, “our”), a company
            incorporated and operating in accordance with the laws of
            Seychelles, is committed to protecting your privacy. This Privacy
            Policy describes how we process the Personal Information, collected
            from you, when using our website and our Products and/or Services.
          </p>
          <p>
            We don’t share information about our clients unless it is required
            for providing our Products and/or Services. All information about
            our clients is held confidential. This website is not intended for
            minors, and we do not knowingly collect data relating to persons
            under the age of 18. It is important that you read this Privacy
            Policy, along with any other privacy notice or processing notice
            that we may provide, on specific occasions, regarding the collecting
            and/or processing Personal Information about you, so that you are
            fully aware of how and why your data is used. This Privacy Policy
            supplements the other notices so provided by us previously. This
            Privacy Policy is based on data protections rules and principles.
          </p>

          <h4>What data do we collect?</h4>
          <p>
            As part of provision of the Products and/or Services, we collect
            your Personal Information, upon you visiting our website. We may
            collect, use, store and transfer different kind of Personal
            Information about you, once you enter our website. “Personal
            Information” means any information from which you can be personally
            identified. We may collect information such as your name, email
            address, phone number etc. Furthermore, we may collect technical
            data about your equipment, hardware from which you access the
            website, such as your IP address, geographical location,
            browser/platform type and version, browsing actions and patterns,
            Internet Service Provider, operating system, referral source/exit
            pages, length of visit, page views, website navigation etc. We
            collect the data by using cookies, server logs and other similar
            technologies.
          </p>
          <p>
            We may receive your Personal Information from various third parties
            and public sources eg. technical data from analytics providers such
            as Google or other third parties or publicly available sources.
          </p>
          <p>
            We do not collect directly any special categories of Personal
            Information about you, including details about your race or
            ethnicity, religious or philosophical beliefs, gender, political
            opinions, trade union membership, information about your health
            and/or genetic and biometric data etc. However, this information may
            be available to us via other third-parties, should such be required.
          </p>

          <h4>How do we collect your Personal Information?</h4>
          <p>
            We may collect your Personal Information through direct interactions
            (forms or other ways of correspondence including but not limited to
            phone, email, skype etc.) or otherwise.
          </p>

          <h4>When do we collect your Personal Information?</h4>
          <p>
            We may collect your Personal Information when you apply for our
            Products and/or Services, subscribe to our publications, request
            marketing material to be communicated to you, enter promotion/survey
            or alike.{" "}
          </p>

          <h4>How will we use your Personal Information?</h4>
          <p>
            We will use your Personal Information for promotional purposes and
            provide you with offers or other Products and/or Services we think
            you might be interested in, to analyze and improve the Products
            and/or Services offered by us.
          </p>

          <h4>How do we store your Personal Information?</h4>
          <p>
            We have put in place appropriate measures to prevent your Personal
            Information from being accidentally lost, used and/or accessed in an
            unauthorized way, altered or disclosed. In addition, we limit access
            to your Personal Information to those employees, agents, contractors
            and other third parties on a need-to-know basis.
          </p>

          <h4>Retention of Personal Information</h4>
          <p>
            We will retain Personal Information only as long as is necessary for
            the purposes for which it is collected, as set out in this Privacy
            Policy. We will also retain it as necessary to comply with our legal
            obligations, for legal and litigation purposes, to maintain accurate
            financial and other records, deal with complaints, and enforce our
            agreements.
          </p>
          <p>
            Generally, in respect of Personal Information that we process, in
            connection with the provision of our Products and/or Services, we
            may retain your Personal Information in accordance with our data
            protection obligations. We may then destroy such files without
            further notice or liability.
          </p>
          <p>
            If any Personal Information is only used for a short period (e.g.,
            for a specific activity, promotion or marketing campaign), we will
            not retain it for longer than the period for which it is used by us.
          </p>

          <h4>What are your rights?</h4>
          <p>
            The right to rectify – You have the right to request correction of
            any Personal Information that we hold about you. This enables you to
            complete and/or modify any incomplete or inaccurate information held
            by us. We reserve the right to verify the accuracy of the new data
            so provided by you.
          </p>

          <h4>
            The right to erase – You have the right to request your Personal
            Information to be erased, under certain conditions:
          </h4>
          <ul>
            <li>
              - If your Personal Information is no longer necessary for the
              purpose which it was originally collected and/or processed;
            </li>
            <li>
              - If we are relying on legitimate interests, as basis for
              processing your Personal Information, and you object to the
              processing of your Personal Information, for as long as there is
              no overriding legitimate interest for us to continue such
              processing;
            </li>
            <li>
              - If we are processing your Personal Information for direct
              marketing purposes and you object to such processing;
            </li>
            <li>
              - If we have processed your Personal Information unlawfully (i.e.
              if we are in breach of the lawful requirement for us collecting
              and/or processing your Personal Information);
            </li>
            <li>
              - If we are required to erase your Personal Information to comply
              with legislative requirements.
            </li>
          </ul>
          <p>
            The right to withdraw consent at any time – where we are relying on
            consent to process your Personal Information, we will rely on such
            consent until and unless it is withdrawn by you. If you withdraw
            your consent, we may not be able to provide certain Products and/or
            Services to you.{" "}
          </p>

          <h4>What are cookies?</h4>
          <p>
            Cookies are text files placed on your computer to collect standard
            internet log information and visitor behavior information. When you
            visit our website, we may collect information from you
            automatically, through cookies or similar technology, in a way which
            enables the website to save and identify the user’s browsing
            activity and individual preferences, for example: to collect
            information about the User’s activity in relation to the Products
            and/or Services and it may serve to recall Personal Information
            previously indicated by a User, such as the user ID and password for
            access to the Product(s) and/or Service(s).
          </p>

          <h4>How do we use cookies?</h4>
          <p>
            Cookies are downloaded by your browser whenever you access our
            website. The stored data is used to track your browsing path and the
            pages you visit most frequently. Cookies are commonly introduced to
            improve the efficiency of websites. By tracking our user(s)’
            behavior, we seek to make our website more user-friendly and
            tailored to your preferences and to get a better understanding of
            how you use our website.
          </p>
          <p>
            We and third parties (eg. Google Analytics) use cookies and other
            tracking technologies to collect information, to communicate,
            analyze trends, administer the website, track user movements around
            the website and gather demographic information.
          </p>

          <h4>What types of cookies do we use?</h4>
          <p>
            Strictly necessary cookies: These cookies are essential for the
            running of our website. Without these cookies our websites would not
            function properly. These are saved temporarily as login information
            and expire once the browser is closed.
          </p>
          <p>
            Preferences cookies: Preference cookies enable our website to
            remember information which changes the way that the website behaves
            or looks, like your preferred language or the region that you are in
            etc.
          </p>
          <p>
            Analytical cookies: The information provided by analytical cookies
            allows us to analyze patterns of user(s)’ behavior and we use that
            information to enhance the overall experience or identify areas of
            our website which may require maintenance. The information is
            anonymous (ie. it cannot be used to identify you and does not
            contain Personal Information such as your name, email address etc)
            and it is only used for statistical purposes.
          </p>
          <p>
            Behavioural cookies: Similar to analytical cookies, behavioural
            cookies are used to provide user(s) with information tailored to
            their interests, based on the behaviour from the previous time(s)
            visiting the website.
          </p>
          <p>
            Promotional cookies: These cookies are used with the intention is to
            display ads that are relevant and appealing to the user and thereby
            used by third-party advertisers.
          </p>

          <h4>Google analytics</h4>
          <p>
            Our websites use the features of Google Analytics Remarketing. The
            feature allows to link target audiences for promotional marketing
            created with Google Analytics Remarketing, through Google AdWords
            and Google DoubleClick. Through this feature, advertising is
            displayed based on your personal interests, identified based on your
            previous use and surfing behavior on a single device (e.g. your
            mobile phone), on other devices (such as a tablet or computer).
            Google will associate your web and app browsing history with your
            Google Account for this purpose. That way, any device that signs
            into your Google Account can use the same personalized promotional
            messaging.
          </p>

          <h4>How to manage your cookies</h4>
          <p>
            Your privacy is important to us. You can, if you wish, set your
            browser to refuse all or some cookies, or to alert you when website
            set or access cookies. If you disable or refuse cookies, however,
            some parts of our website may become inaccessible or may not be
            functioning properly.
          </p>

          <h4>Change of purpose</h4>
          <p>
            We will use your Personal Information for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If we need to use your Personal Information for an
            unrelated purpose, we will notify you respectively and explain the
            legal basis which allows us to do so.
          </p>

          <h4>Disclosures of your Personal Information</h4>
          <p>
            We may have to share your Personal Information with third parties
            for the purpose of providing our Products and/or Services. All
            relevant third parties respect the security of your Personal
            Information and shall treat it in accordance with the legislative
            requirements. We may disclose your Personal Information if such is
            required by law or legal processes; when we believe, if our good
            faith, that the disclosure is necessary to protect our rights,
            protect your safety, to investigate fraud or to respond to a
            government request; in case of change of the ownership or if we are
            involved in a merger, acquisition or sale of all or apportion of our
            assets or with any other third party, upon providing you with the
            prior notification.
          </p>

          <h4>Privacy policies of other websites (third-party links)</h4>
          <p>
            We may provide links to other websites and applications. Clicking on
            those links, or enabling those connections, may allow such third
            parties to collect or share your Personal Information. We have no
            control over such third-party websites and are not responsible for
            their privacy statements. Our Privacy Policy applies only to our
            website. When you leave our website, we encourage you to read the
            privacy notice of every website you visit.
          </p>
          <p>
            The headings used for the sections of this Privacy Policy are for
            reference purposes only and shall in no way affect the meaning or
            interpretation of this Privacy Policy. The obligations and rights
            set forth in this Privacy Policy shall survive any termination or
            expiration of your use of our website.
          </p>

          <h4>Amendments</h4>
          <p>
            Amendments to this Privacy Policy will be posted on this URL and
            will be effective when posted. We encourage you to review this
            Privacy Policy periodically to keep up to date on how we use your
            Personal Information. Your continued use of our websites or Products
            and/or Services, following the posting of any amendment,
            modification, or change to this Privacy Policy, shall constitute
            your acceptance of the relevant amendments. You can choose to
            discontinue use of the websites or Products and/or Services, if you
            do not accept the terms of this Privacy Policy, or any modification
            thereon.
          </p>

          <h4>Merger / Acquisition</h4>
          <p>
            In the event where we go through a business transition, such as a
            merger or acquisition by another company, or sale of all or a
            portion of our assets, collected data will likely be among the
            assets transferred. A prominent notice will be displayed on our
            websites to notify you of any such change in ownership or control.
          </p>
        </article>
      </div>
    </>
  )
}
