import React, { useState } from "react"
import { Link } from "react-router-dom"
import Logo from "./Logo"
import { footer } from "../data/data"
import Skype from "../assets/skype.png";
import Whatsapp from "../assets/whatsapp.png";
import Telegram from "../assets/telegram.png";

export default function Footer() {
  const [items] = useState(footer)

  return (
    <>
      <footer className="py-10 lg:py-20 xl:max-w-5xl xl:mx-auto px-5 2xl:px-0">
        <div className="grid grid-cols-1 gap-5 md:flex md:justify-between">
          <article>
            <h4 className="text-3xl mb-4">Contact Us</h4>
            <ul>
              <li className="font-light mb-4">
                <span className="font-bold">Email:</span>{" "}
                support@trading-technologies.co.uk
              </li>
              <li className="font-light">
                <span className="font-bold">Telephone Number:</span>{" "}
                00 357 94 404 439
              </li>
            </ul>
            <ul className="social-links pt-4">
              <li className="mr-4">
                <a href="https://join.skype.com/invite/AtLhTib1mepc" target="_blank">
                  <img src={Skype} alt="skype" />
                </a>
              </li>
              <li className="mr-4">
                <a href="https://api.whatsapp.com/send/?phone=35794404439&text&app_absent=0" target="_blank">
                  <img src={Whatsapp} alt="whatsapp" />
                </a>
              </li>
              <li className="mr-4">
                <a href="https://t.me/Trading_Technologies" target="_blank">
                  <img src={Telegram} alt="telegram" />
                </a>
              </li>
            </ul>
          </article>

          <article className="md:grid grid-cols-3">
            <ul>
              <h4 className="text-xl mt-5 md:mt-0">Products</h4>
              {items.map(({ id, links }) => (
                <React.Fragment key={id}>
                  {links.map((link, index) => (
                    <li key={index} className="gray-text my-5">
                      <Link to={link.url}>{link.text}</Link>
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>

            <ul className="md:mt-12">
              <li className="gray-text mb-5">
                <Link to="/about-us">About Us</Link>
              </li>
              <li className="gray-text">
                <Link to="/help">Customer Support</Link>
              </li>
            </ul>
          </article>
        </div>

        <article className="mt-10">
          <div>
            <Logo />
          </div>

          <ul className="mt-5">
            <li className="font-light mb-4">2022 Copyright</li>
            <li className="font-light">All Rights Reserved</li>
          </ul>
        </article>
      </footer>
    </>
  )
}
