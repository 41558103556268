import { useState } from "react"
import { technologiesCards } from "../../data/data"

export default function HelpCards() {
  const [cards] = useState(technologiesCards)

  return (
    <>
      <section className="flex flex-wrap items-center justify-center px-5 lg:max-w-3xl lg:mx-auto 2xl:px-0 my-10 xl:my-20 lg:grid lg:grid-cols-3 lg:gap-10">
        {cards.map(({ id, title, image }) => (
          <article
            key={id}
            className="article__cards p-5 rounded w-full sm:w-52 h-44 m-2 lg:m-0"
          >
            <img
              src={image}
              alt={title}
              title={title}
              className="logo w-20 mx-auto"
            />
            <h4 className="text-center text-base font-light mt-5">{title}</h4>
          </article>
        ))}
      </section>
    </>
  )
}
