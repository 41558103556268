import { v4 as uuidv4 } from "uuid"

// Homepage
export const mobileNavbar = [
  {
    id: uuidv4(),
    title: "Trading Platforms",
    url: "/trading-platforms",
  },
  {
    id: uuidv4(),
    title: "Back Office",
    url: "/backoffice",
  },
  {
    id: uuidv4(),
    title: "Technologies",
    url: "/technologies",
  },
  {
    id: uuidv4(),
    title: "Help",
    url: "/help",
  },
  {
    id: uuidv4(),
    title: "About Us",
    url: "/about-us",
  },
]

export const desktopNavbar = [
  {
    id: uuidv4(),
    title: "Trading Platforms",
    url: "/trading-platforms",
    subLinks: [
      {
        id: uuidv4(),
        title: "Web Trader Platform (Desktop/Mobile)",
        url: "/trading-platforms#web-trader-platform",
      },
      {
        id: uuidv4(),
        title: "Our Platform",
        url: "/trading-platforms#our-platform",
      },
      {
        id: uuidv4(),
        title: "Our Manager",
        url: "/trading-platforms#our-manager",
      },
      {
        id: uuidv4(),
        title: "Our Plugins",
        url: "/trading-platforms#our-plugins",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Back Office",
    url: "/backoffice",
    subLinks: [
      {
        id: uuidv4(),
        title: "CRM",
        url: "/backoffice",
      },
      {
        id: uuidv4(),
        title: "Client Analytics",
        url: "crm#client-analytics",
      },
      {
        id: uuidv4(),
        title: "Client Area",
        url: "/backoffice#client-area",
      },
      {
        id: uuidv4(),
        title: "Marketing and Affiliates",
        url: "/backoffice#marketing-and-affiliates",
      },
      {
        id: uuidv4(),
        title: "Reports",
        url: "/backoffice#reports",
      },
      {
        id: uuidv4(),
        title: "Payment Solutions",
        url: "/backoffice#payment-solutions",
      },
      {
        id: uuidv4(),
        title: "Risk Management",
        url: "/backoffice#risk-management",
      },
      {
        id: uuidv4(),
        title: "Liquidity Providers",
        url: "/backoffice#liquidity-providers",
      },
      {
        id: uuidv4(),
        title: "Compliance",
        url: "/backoffice#compliance",
      },
      {
        id: uuidv4(),
        title: "Anti-Fraud Management",
        url: "/backoffice#anti-fraud-management",
      },
      {
        id: uuidv4(),
        title: "Algo Trading API",
        url: "/backoffice#algo-trading-api",
      },
      {
        id: uuidv4(),
        title: "Social Trading API",
        url: "/backoffice#social-trading-api",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Technologies",
    url: "/technologies",
    subLinks: [
      {
        id: uuidv4(),
        title: "Hosting Services",
        url: "/technologies#hosting-services",
      },
      {
        id: uuidv4(),
        title: "Feed",
        url: "/technologies#feed",
      },
      {
        id: uuidv4(),
        title: "IT Services",
        url: "/technologies#it-services",
      },
      {
        id: uuidv4(),
        title: "Cybersecurity",
        url: "/technologies#cybersecurity",
      },
      {
        id: uuidv4(),
        title: "Firewalls",
        url: "/technologies#firewalls",
      },
      {
        id: uuidv4(),
        title: "Software Licences and Solutions",
        url: "/technologies#software-licences-and-solutions",
      },
      {
        id: uuidv4(),
        title: "Installation Setup",
        url: "/technologies#installation-setup",
      },
      {
        id: uuidv4(),
        title: "Website Creation",
        url: "/technologies#website-creation",
      },
      {
        id: uuidv4(),
        title: "Branded Emails",
        url: "/technologies#branded-emails",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Help",
    url: "/help",
    subLinks: [
      {
        id: uuidv4(),
        title: "Customer Care",
        url: "/help#customer-care",
      },
      {
        id: uuidv4(),
        title: "Customer Support",
        url: "/help#customer-support",
      },
      {
        id: uuidv4(),
        title: "Consulting",
        url: "/help#consulting",
      },
      {
        id: uuidv4(),
        title: "Success Manager",
        url: "/help#success-manager",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "About Us",
    url: "/about-us",
    subLinks: [
      {
        id: uuidv4(),
        title: "About Us",
        url: "/about-us",
      },
      {
        id: uuidv4(),
        title: "Contact Us",
        url: "/about-us#contact-us",
      },
    ],
  },
]

export const tradingPlatforms = [
  {
    id: uuidv4(),
    title: "High quality platforms",
    desc: "We offer advanced trading platforms that suit every type of trader out there, available as superb solutions for your brand to grow and develop, preserving its clients.",
    image: [
      {
        mobile: "./assets/high-quality-platforms-mobile.jpg",
        desktop: "./assets/high-quality-platforms-desktop.jpg",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Back Office",
    desc: "Our easy-to-navigate through Back office system allows the management of your entire company from one single location. Suitable for companies of any size, our product  is a leading multi-purpose tool for reaching the best possible performance results.",
    image: [
      {
        mobile: "./assets/crm-mobile.jpg",
        desktop: "./assets/crm-desktop.jpg",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Simple API Integrations",
    desc: "Use our accessible API integration infrastructure to seamlessly link with multiple 3rd party providers such as Affiliates, Cashiers, PSPs, VoIP, Email services, and many more.",
    image: [
      {
        mobile: "./assets/simple-integration-mobile.jpg",
        desktop: "./assets/simple-integration-desktop.jpg",
      },
    ],
  },
]

export const customizableCRM = [
  {
    id: uuidv4(),
    title: "Our Customizable Back Office",
    desc: "Trading Technologies Back Office provides a seamless connection between your Clients and Brokerage Staff. Marketing, Sales, Onboarding, Engagement, Service, Accounting, Analytics deeply integrated with Multiple Trading Platforms, Payment Systems assisted by incentive and referral solutions that streamline brokerage operations and customer experience. Never lose any leads and keep track of all activity in one place - for example, your traders' activities, such as deposits and withdrawals are also stored there.",
    image: [
      {
        mobile: "./assets/customizable-crm-mobile.jpg",
        desktop: "./assets/customizable-crm-desktop.jpg",
      },
    ],
    button: "Back office",
    list: [
      {
        text: "Automated activities for minimal cost;",
      },
      {
        text: "Built-in Task Manager;",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Risk Management",
    desc: "In the day-to-day work, Brokerage systems come across various issues down their path that they need to confront. Without a comprehensive system to check technical & market risk in the industry, you will have to deal with all those intense difficulties on your own. That's why we included more build-in tools in the Risk Managing section which are a great aid for you and your employees.",
    image: [
      {
        mobile: "./assets/risk-management-mobile.jpg",
        desktop: "./assets/risk-management-desktop.jpg",
      },
    ],
    button: "Risk Management",
    list: [
      {
        text: "Market exposure;",
      },
      {
        text: "Trader Exposure;",
      },
      {
        text: "Bridge Log;",
      },
      {
        text: "Margin Call Accounts;",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Reports",
    desc: "Generate various valuable reports using the advanced configurable Reporting Tools provided. Use rich and fully customizable dashboards to:",
    image: [
      {
        mobile: "./assets/reports-mobile.jpg",
        desktop: "./assets/reports-desktop.jpg",
      },
    ],
    button: "Reports",
    list: [
      {
        text: "Visualize comparisons;",
      },
      {
        text: "Manage risk and compliance;",
      },
      {
        text: "View patterns and trends in sales, as well as in marketing;",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "PSP/Banking API Integration",
    desc: "We allow our customers to choose and integrate alternative payment systems that suit their personal and users' needs and preferences. Provide a seamless and secured way for your clients to complete their online transactions.",
    image: [
      {
        mobile: "./assets/psp-mobile.jpg",
        desktop: "./assets/psp-desktop.jpg",
      },
    ],
    button: "PSP/Banking",
    list: [
      {
        text: "Transactions;",
      },
      {
        text: "Crypto Balance;",
      },
      {
        text: "Crypto Transactions;",
      },
      {
        text: "Internal and external Transfers;",
      },
    ],
  },
]

export const homepageCards = [
  {
    id: uuidv4(),
    icon: "./assets/checkmark.svg",
    desc: "Seamless connection between your Clients and Brokerage Staff",
  },
  {
    id: uuidv4(),
    icon: "./assets/checkmark.svg",
    desc: "Custom notification center that will keep you updated wherever you are",
  },
  {
    id: uuidv4(),
    icon: "./assets/checkmark.svg",
    desc: "Payment Systems assisted by incentive and referral solutions",
  },
  {
    id: uuidv4(),
    icon: "./assets/checkmark.svg",
    desc: "Keep track of all activity in one place",
  },
  {
    id: uuidv4(),
    icon: "./assets/checkmark.svg",
    desc: "Smooth KYC process, Account Registration, and Funding, Live chat, and promotions",
  },
]

export const footer = [
  {
    id: uuidv4(),
    title: "Products",
    links: [
      {
        text: "Privacy Policy",
        url: "/privacy-policy",
      },
      {
        text: "Back Office",
        url: "/backoffice",
      },
      {
        text: "Web Trader",
        url: "/trading-platforms#web-trader",
      },
      {
        text: "Cybersecurity",
        url: "/technologies#cybersecurity",
      },
    ],
  },
]
// End of homepage

// CRM Page
export const crmCards = [
  {
    id: uuidv4(),
    image: "./assets/check.svg",
    title: "Compliance",
  },
  {
    id: uuidv4(),
    image: "./assets/trophy.svg",
    title: "Reports Center",
  },
  {
    id: uuidv4(),
    image: "./assets/customer-care.svg",
    title: "In-house Support Team",
  },
  {
    id: uuidv4(),
    image: "./assets/graph.svg",
    title: "Live Trading Data",
  },
  {
    id: uuidv4(),
    image: "./assets/algo.svg",
    title: "Algo Trading API",
  },
  {
    id: uuidv4(),
    image: "./assets/network.svg",
    title: "Social Trading API",
  },
  {
    id: uuidv4(),
    image: "./assets/dashboard.svg",
    title: "Client Area",
  },
  {
    id: uuidv4(),
    image: "./assets/anti-fraud.svg",
    title: "Anti-Fraud Management",
  },
]

export const crmSlider = [
  {
    id: uuidv4(),
    image: "./assets/crm-risk-management.jpeg",
    button: "Risk Management",
    links: [
      {
        text: "Currency exposure",
      },
      {
        text: "Instrument exposure",
      },
      {
        text: "Trader exposure",
      },
      {
        text: "Margin Call Accounts",
      },
      {
        text: "Bridge Log",
      },
      {
        text: "Live Prices",
      },
    ],
  },
  {
    id: uuidv4(),
    image: "./assets/crm-reports.png",
    button: "Reports",
    links: [
      {
        text: "Big Winners",
      },
      {
        text: "Big Losers",
      },
      {
        text: "Profit & Loss",
      },
      {
        text: "Social Copiers",
      },
      {
        text: "Social Leaders",
      },
      {
        text: "IP Fraud",
      },
    ],
  },
  {
    id: uuidv4(),
    image: "./assets/crm-sales.png",
    button: "Sales",
    links: [
      {
        text: "Leads, Users & Customers",
      },
      {
        text: "First Time Deposit",
      },
      {
        text: "Sales, Partners & Affiliates",
      },
      {
        text: "Revenues, Fees, Payments",
      },
      {
        text: "Trading Account",
      },
      {
        text: "New customer, Lead or User",
      },
    ],
  },
  {
    id: uuidv4(),
    image: "./assets/crm-payment-solutions.png",
    button: "Payment solutions",
    links: [
      {
        text: "Transactions",
      },
      {
        text: "Payment Gateways",
      },
      {
        text: "Cryptos Balance",
      },
      {
        text: "Internal Transfers",
      },
      {
        text: "External Transfers",
      },
      {
        text: "Bitcoin Transactions",
      },
    ],
  },
]
// End of CRM page

// Trading Platforms page
export const tradingPlatformsCards = [
  {
    id: uuidv4(),
    title: "Fast Order Executions",
    image: "./assets/timer.svg",
  },
  {
    id: uuidv4(),
    title: "Multiple Instruments To Choose From",
    image: "./assets/globe.svg",
  },
  {
    id: uuidv4(),
    title: "User-Friendly Navigation",
    image: "./assets/compass.svg",
  },
]

export const tradingPlatformsArticles = [
  {
    id: uuidv4(),
    title: "Our Client Terminal",
    desc: "A fully branded Our client terminal allows you to offer your traders an all-inclusive, easy-to-navigate and secured online trading experience on the most popular forex trading platform, all under your own brand.",
  },
  {
    id: uuidv4(),
    title: "Our Manager",
    desc: "Gain fast and easy access to all trader account data, account preferences, and trade history through the Our Manager. A simple and effective way to better manage and monitor your clients trading activity",
  },
  {
    id: uuidv4(),
    title: "Our Plugins",
    desc: "Harness the full potential of your Our platform through our wide variety of feature-rich plugins, designed, rigorously tested, and updated regularly by our team of highly experienced developers.",
  },
]
// End of Trading Platforms page

// Technologies page
export const technologiesCards = [
  {
    id: uuidv4(),
    title: "IT Services",
    image: "./assets/it-services.svg",
  },
  {
    id: uuidv4(),
    title: "Installation Setup",
    image: "./assets/installation-setup.svg",
  },
  {
    id: uuidv4(),
    title: "Hosting Services",
    image: "./assets/hosting-services.svg",
  },
  {
    id: uuidv4(),
    title: "Software Licences and Solutions",
    image: "./assets/software-licences.svg",
  },
  {
    id: uuidv4(),
    title: "Firewalls",
    image: "./assets/firewall.svg",
  },
  {
    id: uuidv4(),
    title: "Branded Emails",
    image: "./assets/branded-emails.svg",
  },
]

export const webDesign = [
  {
    id: uuidv4(),
    title: "Pre-made Template Website",
    image: "./assets/technologies-premade-website.jpg",
    desc: [
      {
        text: "The Pre-made Templates will be fully integrated and ready within 24h.",
      },
      {
        text: "You just need to provide us with your logo and be sure that you will be having your website the day after. We can integrate ready content or implement your own.",
      },
    ],
    button: "Request a Free Demo",
  },
  {
    id: uuidv4(),
    title: "Tailored-made Website",
    image: "./assets/technologies-tailormade-website.jpg",
    desc: [
      {
        text: "We are ready to create a brand new website for you or re-design the one already have.",
      },
      {
        text: "Get help from Marketing Experts who can promote your Website and Web Designers who can develop your Brands’ look—we’ve got the right team for you. Get complete design freedom to create your website.",
      },
    ],
    button: "Request a Free Demo",
  },
]

export const cybersecurity = [
  {
    id: uuidv4(),
    title: "Cybersecurity",
    desc: [
      {
        text: 'With many years of experience, our employees are one of the highest graded professionals in the industry which can offer "military" level security for your company. The team is always up to date with the new threats and attacks, so it can keep your company\'s security on a top-level at all times.',
      },
    ],
    image: "./assets/technologies-cybersecurity.jpg",
    list: [
      {
        text: "Antivirus solutions",
      },
      {
        text: "Penetration Testing",
      },
      {
        text: "Awareness Education",
      },
      {
        text: "24/7 Security Monitoring (SOC)",
      },
      {
        text: "Endpoint detection and response solution",
      },
      {
        text: "Phishing Awareness Drill and Anti-phishing solution",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Branded emails",
    desc: [
      {
        text: "Powerful, sleek and sophisticated web-based trading platform that syncs seamlessly with the Our/New.",
      },
      {
        text: "Fully branded user interface with customizable features lets you set your brand apart from the rest.",
      },
    ],
    image: "./assets/technologies-branded-emails.jpg",
    list: [
      {
        text: "",
      },
    ],
  },
]
// End of Technologies page

// Help page
export const helpCards = [
  {
    id: uuidv4(),
    title: "Customer Care",
    image: "./assets/customer-care.svg",
  },
  {
    id: uuidv4(),
    title: "Consulting",
    image: "./assets/consulting.svg",
  },
  {
    id: uuidv4(),
    title: "Success Manager",
    image: "./assets/trophy-2.svg",
  },
]
// End of Help page

// About Us page
export const aboutUsCards = [
  {
    id: uuidv4(),
    title: "Technological Innovations",
    desc: "We strive to be the best leading provider for technological innovations in the industry!",
  },
  {
    id: uuidv4(),
    title: "Customer Satisfaction",
    desc: "Seek our customers' satisfaction is an absolute must for our company!",
  },
  {
    id: uuidv4(),
    title: "Self-Improvment",
    desc: "This is the main motto of our company, because we believe that tomorrow we can do better than today!",
  },
]
// End of About Us page
