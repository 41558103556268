import { Link } from "react-router-dom"

export default function Error() {
  return (
    <>
      <section className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-4xl text-center mb-10">
          Sorry, the page you are looking for does not exist or may have moved
        </h1>
        <Link to="/" className="btn btn-white block mx-auto text-center">
          Back to Homepage
        </Link>
      </section>
    </>
  )
}
