import { useState } from "react"
import { aboutUsCards } from "../../data/data"

export default function AboutUsCards() {
  const [cards] = useState(aboutUsCards)

  return (
    <>
      <h2 className="text-center mt-10 mb-5 xl:mt-20 xl:mb-10">
        Our Fundamental Values
      </h2>
      <section className="flex flex-col justify-center flex-wrap sm:grid grid-cols-2 gap-5 lg:grid-cols-3 lg:items- px-5 xl:max-w-5xl xl:mx-auto 2xl:px-0">
        {cards.map(({ id, title, desc }) => (
          <article key={id} className="article__cards p-5 rounded mx-2">
            <h4 className="text-center font-bold text-xl mb-5">{title}</h4>
            <p className="text-center">{desc}</p>
          </article>
        ))}
      </section>
    </>
  )
}
