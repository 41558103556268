import { CRMHero, CRMCards, Liquidity, CRMSlides } from "."
import Form from "../components/Form"

export default function CRM() {
  return (
    <>
      <CRMHero />
      <CRMCards />
      <CRMSlides />
      <Liquidity />

      <div
        className="article__cards py-10 px-5 2xl:px-0 lg:pt-14 lg:pb-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <article className="xl:max-w-7xl xl:mx-auto">
          <h2 className="text-center mb-10 lg:mb-20">
            Message Us for a <span>CRM</span> &amp;
            <span>
              Trading <br /> Platform{" "}
            </span>
            Package
          </h2>
          <Form />
        </article>
      </div>
    </>
  )
}
