import { BookADemo } from "../buttons"

export default function Home() {
  return (
    <>
      <section className="home">
        <div className="overlay flex items-center justify-center flex-col lg:items-start xl:max-w-6xl xl:mx-auto px-5 2xl:px-0 text-center lg:text-left">
          <h1 className="mb-5">
            <span>The most efficient</span> Trading Platform{" "}
            <span>on the Market</span>
          </h1>
          <p className="mb-10 lg:w-1/2">
            No need to look for any other 3rd party services providers we give
            you everything you need for a successful Brokerage.
          </p>

          <BookADemo />
        </div>
      </section>
    </>
  )
}
