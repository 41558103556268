import React, { useState } from "react"
import { FaCircle } from "react-icons/fa"
import { tradingPlatforms } from "../data/data"

export default function TradingPlatforms() {
  const [cards] = useState(tradingPlatforms)
  const [value, setValue] = useState(0)

  const { id, title, desc, image } = cards[value]

  return (
    <>
      <section className="px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto py-10 lg:pt-20 lg:pb-16">
        <h2 className="lg:w-1/2 lg:text-5xl">
          Our Outstanding Trading Platforms
        </h2>
        <article className="mt-5 lg:flex flex-row-reverse items-center justify-center">
          <div>
            {image.map(({ mobile, desktop }) => (
              <picture key={id}>
                <source media="(min-width: 768px)" srcSet={desktop} />
                <img src={mobile} alt={title} title={title} />
              </picture>
            ))}
          </div>
          <div className="lg:w-2/3 mr-10">
            <div className="lg:hidden">
              <h3 className="mt-3">{title}</h3>
              <div className="green-underline"></div>
              <p>{desc}</p>
            </div>

            <div className="hidden lg:block">
              {cards.map((item, index) => (
                <React.Fragment>
                  <h3
                    onClick={() => setValue(index)}
                    className={`text-xl mt-5 cursor-pointer opacity-60 ${
                      index === value && "text-3xl opacity-100"
                    }`}
                  >
                    {item.title}
                  </h3>
                  <div className="green-underline"></div>
                  <p
                    onClick={() => setValue(index)}
                    className={`cursor-pointer text-sm opacity-60 ${
                      index === value && "text-base opacity-100"
                    }`}
                  >
                    {item.desc}
                  </p>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-center my-5 lg:hidden">
            {cards.map((item, index) => (
              <button
                key={index}
                className={`mx-5 gray-text ${index === value && "white-text"}`}
                onClick={() => setValue(index)}
              >
                <FaCircle />
              </button>
            ))}
          </div>
        </article>
      </section>
    </>
  )
}
