import { useState } from "react"
import { customizableCRM } from "../data/data"

export default function CustomizableCRM() {
  const [cards] = useState(customizableCRM)
  const [value, setValue] = useState(0)

  const { id, title, desc, image, list } = cards[value]

  return (
    <>
      <section className="px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto py-10">
        <article className="relative">
          <div className="crm__buttons mb-52 md:mb-5">
            {cards.map((item, index) => (
              <button
                key={index}
                onClick={() => setValue(index)}
                className={`py-1 px-6 border-2 border-white rounded-tl-2xl rounded-br-2xl w-52 ${
                  index === value && "text-green-500 border-green-500"
                }`}
              >
                {item.button}
              </button>
            ))}
          </div>

          <div className="lg:grid grid-cols-2 gap-10 lg:mt-20">
            <article>
              {image.map(({ mobile, desktop }) => (
                <picture key={id}>
                  <source media="(min-width: 768px)" srcSet={desktop} />
                  <img src={mobile} alt={title} title={title} />
                </picture>
              ))}
            </article>

            <article>
              <h3 className="mt-3 lg:mt-0 lg:-mt-5">{title}</h3>
              <div className="green-underline"></div>
              <p className="mt-5">{desc}</p>

              <ul>
                {list.map((item, index) => (
                  <li key={index} className="font-bold my-4">
                    {item.text}
                  </li>
                ))}
              </ul>
            </article>
          </div>
        </article>
      </section>
    </>
  )
}
