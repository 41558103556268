import tradingPlatformMobile from "../assets/trading-platform-mobile.jpg"
import tradingPlatformDesktop from "../assets/trading-platform-desktop.jpg"

export default function Section3() {
  return (
    <>
      <section className="px-5 xl:max-w-6xl xl:mx-auto 2xl:px-0 flex flex-col-reverse items-center justify-center md:grid grid-cols-2 gap-10 py-10">
        <article>
          <h3>Trading Platform</h3>
          <div className="green-underline"></div>
          <p>
            Our web application serves as your one-stop destination to check
            quotes and place trades, see account balances, P&amp;L, and other
            key performance metrics such as funding, reporting, and more. It is
            a popular choice among traders and brokers with its user-friendly
            interface.
          </p>
          <ul className="mt-10">
            <li className="my-2 font-bold">
              Suitable for all traders from all backgrounds and experiences;
            </li>
            <li className="my-2 font-bold">
              Reliable software with smooth performance;
            </li>
            <li className="my-2 font-bold">
              Tailored for the clients' needs and preferences;
            </li>
            <li className="my-2 font-bold">
              Build-in advanced trading tools for analyzing trends and market
              changes;
            </li>
          </ul>
        </article>

        <article>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={tradingPlatformDesktop}
            />
            <img
              src={tradingPlatformMobile}
              alt="Trading Platform"
              title="Trading Platform"
              className="w-full"
            />
          </picture>
        </article>
      </section>
    </>
  )
}
