import { useState } from "react"
import { crmCards } from "../../data/data"

export default function CRMCards() {
  const [cards] = useState(crmCards)

  return (
    <>
      <section className="px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto grid grid-cols-1 gap-5 sm:grid-cols-2 py-10 md:grid-cols-3 lg:grid-cols-4 lg:gap-10 lg:pt-20 lg:pb-16">
        {cards.map(({ id, image, title }) => (
          <article key={id} className="article__cards p-5 rounded">
            <img
              src={image}
              alt={title}
              tite={title}
              className="logo w-10 mb-5 mx-auto block"
            />
            <h5 className="text-center font-bold">{title}</h5>
          </article>
        ))}
      </section>
    </>
  )
}
