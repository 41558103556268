import aboutUs from "../assets/about-us-hero.jpg"
import Form from "../components/Form"
import AboutUsCards from "./components/AboutUsCards"

export default function AboutUs() {
  return (
    <>
      <section className="home">
        <div className="overlay flex items-center justify-center flex-col xl:max-w-5xl xl:mx-auto pt-20 px-5 2xl:px-0 text-center">
          <h1 className="mb-5">
            <span>Our </span>Story
          </h1>
          <p className="mb-10 lg:w-10/12 text-gray-300">
            With many years operating on the Market, we had grown immensely.
            Having past experience of being a Start-up , we know exactly what
            new companies need so they can grow big. Our fast development
            experience also benefits us when we provide services to big and
            already established companies which need additional products. So
            whatever your next step is, don't hesitate to contact us!
          </p>
        </div>
      </section>
      <article className="px-5 2xl:px-0">
        <img
          src={aboutUs}
          alt="Our Story"
          title="Our Story"
          className="lg:w-1/2 lg:mx-auto -mt-10 sm:-mt-16 md:-mt-24 img__about__us lg:-mt-36"
        />
        <div className="mt-10 lg:w-2/3 mx-auto text-center xl:w-auto xl:max-w-5xl">
          <p>
            Since our foundation, our main goal has been surpassing the highest
            expectations in the technological field with a focus on providing
            high-quality products for the Brokers and their clients.
          </p>
          <p className="my-5">
            Our Core values have always been mainly centered on ensuring a
            fantastic and unforgettable customer experience with platforms that
            are straightforward, with easy navigation, and satisfying user
            interface and experience. By releasing new and exciting products
            into the market, such as a new Trading Platform and CRM System, we
            had set ourselfs apart as a key player in the trading industry.
          </p>
          <p>
            By using Trading Core services, our clients are able to save
            valuable time and provide their customers with state-of-the-art
            technologies without having to hire a single developer. We offer an
            in-house support team and dedicated account manager for all of its
            brokers, in order to keep client relations as a top priority. Here
            at Trading Technologies, we want to take care of your
            business and treat you as a part of the family!
          </p>
        </div>
      </article>

      <AboutUsCards />

      <div
        className="article__cards px-5 2xl:px-0 py-10 xl:py-20 mt-10 xl:mt-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <article className="xl:max-w-7xl xl:mx-auto">
          <h2 className="text-center mb-10">
            Do you want to get to know us better?
          </h2>
          <Form />
        </article>
      </div>
    </>
  )
}
