import { useState } from "react"
import delight from "../../assets/delight.jpg"
import { tradingPlatformsArticles } from "../../data/data"

export default function Delight() {
  const [cards] = useState(tradingPlatformsArticles)

  return (
    <>
      <section
        className="light__green pt-16 pb-72 my-10 xl:my-20 md:pb-36 xl:pb-44"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <h3 className="text-center px-5 2xl:px-0 mb-5 lg:w-1/2 lg:mx-auto">
          Enjoy the delight of Trading{" "}
          <span className="block font-bold">with our Agile Platform</span>
        </h3>
        <p className="px-5 2xl:px-0 text-center lg:w-2/3 lg:mx-auto mb-10">
          The Our trading platform provided by us as Our White Label provider is
          fully hosted within our servers and is configured{" "}
          <span className="block">
            by the WL4 experts to provide you with business and marketing
            advantages above its competitors. We offer you:
          </span>
        </p>

        <article className="px-5 2xl:px-0">
          <img
            src={delight}
            alt="Enjoy the delight of Trading with our Agile Platform"
            title="Enjoy the delight of Trading with our Agile Platform"
            style={{
              borderTopRightRadius: "3.5rem",
              borderBottomLeftRadius: "3.5rem",
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            className="lg:w-1/2 lg:mx-auto"
          />
        </article>
      </section>

      <div className="grid grid-cols-1 gap-5 -mt-72 md:-mt-36 xl:-mt-44 px-5 md:grid-cols-2 lg:grid-cols-3 xl:max-w-6xl xl:mx-auto">
        {cards.map(({ id, title, desc }) => (
          <article
            key={id}
            className="bg-white py-8 px-5 rounded-2xl text-center"
          >
            <h4 className="light__green__text text-lg lg:text-2xl mb-5">
              {title}
            </h4>
            <p className="text-gray-800">{desc}</p>
          </article>
        ))}
      </div>
    </>
  )
}
