import { useState } from "react"
import { webDesign } from "../../data/data"

export default function WebDesign() {
  const [cards] = useState(webDesign)

  return (
    <>
      <section className="article__cards py-10 xl:py-20">
        <h2 className="text-center">Web Design and Development</h2>
        <div className="green__centered__underline"></div>
        <p className="font-bold text-center lg:w-1/2 lg:mx-auto mb-10">
          <span className="font-light">
            Our company offers two types of Websites:
          </span>{" "}
          Pre-made Templates <span className="font-light">and</span> <br />
          Tailored-made according to your requirements and needs.
        </p>

        <div className="xl:max-w-6xl xl:mx-auto">
          {cards.map(({ id, title, image, desc, button }) => (
            <div
              key={id}
              className="web__design__div grid grid-cols-1 gap-5 items-center px-5 2xl:px-0 mb-10 lg:mb-20 lg:gap-10 xl:gap-20"
            >
              <article>
                <h3 className="mt-5">{title}</h3>
                <div className="green-underline"></div>
                {desc.map((item, index) => (
                  <p className="my-5" key={index}>
                    {item.text}
                  </p>
                ))}
                <a href="#contact-form" className="btn btn-green my-5">{button}</a>
              </article>

              <article>
                <img src={image} alt={title} title={title} />
              </article>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
