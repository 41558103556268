import customerSupport from "../../assets/customer-support.jpg"

export default function CustomerSupport() {
  return (
    <div
      className="light__green"
      style={{
        borderTopLeftRadius: "3.5rem",
        borderBottomRightRadius: "3.5rem",
      }}
    >
      <section className="pt-8 pb-14 px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto flex flex-col items-center justify-center text-center lg:text-left lg:grid lg:grid-cols-2 lg:gap-10 xl:py-20">
        <article className="mb-10">
          <h2>Customer support</h2>
          <div className="white-underline mx-auto lg:ml-0"></div>
          <p className="md:w-1/2 md:mx-auto lg:w-auto">
            Our friendly team is always ready to go to extra lengths to help you
            with whatever you are struggling with. Forget all your worries -
            instead, just leave them to us!{" "}
          </p>
          <ul className="my-6">
            <li className="font-bold">Competent Customer Support;</li>
            <li className="font-bold">Consulting;</li>
            <li className="font-bold">Success Manager;</li>
          </ul>
          <a href="#contact-form" className="btn btn-white-text-green">
            Request a free demo
          </a>
        </article>

        <article>
          <img
            src={customerSupport}
            alt="Customer Support"
            title="Customer Support"
            className="img w-full"
          />
        </article>
      </section>
    </div>
  )
}
