import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

import { Book } from "../buttons"
import { CgPhone } from 'react-icons/cg';

export default function Form() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_l2gvgsj', 'template_fvcd7kr', form.current, 'user_YqdXBLYfCt9S3vDdCQLr7')
      .then((result) => {
          console.log(result.text);
          alert('Thank you! We will contact you as soon');
          form.current.reset()
      }, (error) => {
          console.log(error.text);
      });
  };
  
  
  return (
    <>
      <form id="contact-form" ref={form} onSubmit={sendEmail} className="flex justify-center flex-col flex-wrap xl:max-w-5xl xl:mx-auto px-5 2xl:px-0 xl:gap-10 md:items-center md:justify-center">
        <div className="flex flex-col md:grid grid-cols-3">
          <input type="text" placeholder="Name / Company Name" name="name" required />
          <input
            name="email"
            type="email"
            placeholder="Email address"
            className="my-10 md:my-0 md:mx-5"
            required
          />
          <input
            type="tel" 
            placeholder="Phone number" 
            name="phone" 
            required 
          />
        </div>
        <div className="mt-10 pt-10 lg:mt-0">
          <Book />
        </div>
      </form>
    </>
  )
}
