import technologies from "../assets/technologies.jpg"
import Form from "../components/Form"
import Cybersecurity from "./components/CyberSecurity"
import TechnologiesCards from "./components/TechnologiesCards"
import WebDesign from "./components/WebDesign"

export default function Technologies() {
  return (
    <>
      <section className="home technologies">
        <div className="overlay flex items-center justify-center flex-col xl:max-w-6xl xl:mx-auto px-5 2xl:px-0 text-center pt-20">
          <h1 className="mb-5">
            <span>Find</span> all the technical services <span>you need</span>{" "}
            in one place
          </h1>
          <p className="mb-10 lg:w-2/3">
            The Trading Technologies can provide your company with
            different IT services, including Website Design and Development,
            Cybersecurity services, VPS Hosting, and many more.
          </p>
        </div>
      </section>
      <article className="px-5 2xl:px-0">
        <img
          src={technologies}
          alt="Technologies"
          title="Technologies"
          className="lg:w-1/2 lg:mx-auto -mt-5 sm:-mt-16 lg:-mt-28"
        />
      </article>

      <TechnologiesCards />
      <WebDesign />
      <Cybersecurity />

      <div
        id="contact-form"
        className="article__cards px-5 2xl:px-0 py-10 xl:py-20 mt-10 xl:mt-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <article className="xl:max-w-7xl xl:mx-auto">
          <h2 className="text-center mb-10">
            <span>Learn More About</span> Our Tech Services
          </h2>
          <Form />
        </article>
      </div>
    </>
  )
}
