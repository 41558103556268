import React, { useState } from "react"
import { Link } from "react-router-dom"
import { FaBars } from "react-icons/fa"
import { CgClose } from "react-icons/cg"
import CookieConsent from "react-cookie-consent"
import { BookADemo } from "../buttons"
import { desktopNavbar, mobileNavbar } from "../data/data"
import Logo from "./Logo"

export default function Header() {
  const [links] = useState(mobileNavbar)
  const [desktopLinks] = useState(desktopNavbar)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    const navbar = document.querySelector(".navbar")
    const listItems = document.querySelectorAll(".list-item")

    navbar.classList.toggle("open")

    listItems.forEach((item) =>
      item.addEventListener("click", () => navbar.classList.remove("open"))
    )
  }

  return (
    <>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept All Cookies"
          cookieName="Trading Technologies"
          style={{ backgroundColor: "#00b48b" }}
          buttonStyle={{
            color: "#ffffff",
            backgroundColor: "#debd3f",
            fontSize: "16px",
          }}
          expires={150}
          enableDeclineButton
          declineButtonStyle={{ backgroundColor: "#ffffff", color: "#111827" }}
          onDecline={() => console.log("User did not accept cookies")}
        >
          This website uses cookies to enhance user experience.
        </CookieConsent>
      </div>
      <header className="header py-5 flex items-center justify-between lg:grid lg:grid-cols-1 xl:max-w-6xl xl:mx-auto">
        <article className="lg:flex lg:items-center lg:justify-between">
          <div>
            <Logo />
          </div>

          {/* Desktop hamburger */}
          <div className="hidden lg:block">
            <ul className="flex items-center">
              <li className="mr-5">
                <a href="#contact-form" className="btn btn-green">Book A Demo</a>
              </li>
              <li>
                <button onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? (
                    <CgClose className="text-white text-3xl" />
                  ) : (
                    <FaBars className="text-white text-3xl" />
                  )}
                </button>
              </li>
            </ul>
          </div>
        </article>

        {/* Mobile Navbar */}
        <nav className="navbar lg:hidden">
          <ul className="flex items-end justify-end flex-col">
            {links.map(({ id, title, url }) => (
              <React.Fragment key={id}>
                <li className="list-item mb-5">
                  <Link to={url}>{title}</Link>
                </li>
              </React.Fragment>
            ))}
            <li>
              <BookADemo />
            </li>
          </ul>
        </nav>

        {/* Desktop Navbar */}
        {isOpen && (
          <nav className="hidden lg:block mt-8 bg-gray-900 p-5 rounded-lg">
            <ul className="grid gap-10 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {desktopLinks.map(({ id, title, url, subLinks }) => (
                <React.Fragment key={id}>
                  <li className="text-white mb-5">
                    <Link
                      to={url}
                      onClick={() => setIsOpen(false)}
                      className="font-bold text-xl"
                    >
                      {title}
                    </Link>
                    <div className="green-underline"></div>
                    {subLinks.map((link) => (
                      <li key={link.id} className="my-4">
                        <Link to={link.url} onClick={() => setIsOpen(false)}>
                          {link.title}
                        </Link>
                      </li>
                    ))}
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </nav>
        )}

        <div className="lg:hidden">
          <button onClick={handleClick}>
            <FaBars className="text-3xl" />
          </button>
        </div>
      </header>
    </>
  )
}
