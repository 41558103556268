import leads from "../../assets/trading-platforms-webtrader.jpg"
import { BookADemo } from "../../buttons"

export default function Leads() {
  return (
    <>
      <section
        id="web-trader"
        className="grid grid-cols-1 gap-5 px-5 2xl:px-0 md:grid-cols-2 lg:gap-10 xl:max-w-6xl xl:mx-auto items-center"
      >
        <article>
          <img
            src={leads}
            alt="Turn your leads into loyal customers"
            title="Turn your leads into loyal customers"
          />
        </article>

        <article>
          <h4 className="lg:text-4xl lg:pr-20">
            Turn Your Leads Into Loyal Customers With Our Webtrader
          </h4>
          <div className="green-underline"></div>
          <p className="mt-5 mb-10 lg:mt-10">
            Our WebTrader is customized to the client's needs and can be branded
            according to the broker's request at no extra cost. It's a powerful,
            sleek, and sophisticated web-based trading platform that syncs
            seamlessly with the Our/New. A fully branded user interface with
            customizable features that let you set your brand apart from the
            rest.
          </p>
          <BookADemo />
        </article>
      </section>
    </>
  )
}
