import { useState } from "react"
import { cybersecurity } from "../../data/data"

export default function Cybersecurity() {
  const [cards] = useState(cybersecurity)

  return (
    <>
      <section id="cybersecurity" className="pt-10 xl:pt-14">
        <div className="xl:max-w-6xl xl:mx-auto">
          {cards.map(({ id, title, desc, image, list }) => (
            <div
              key={id}
              className="web__design__div grid grid-cols-1 gap-5 items-center px-5 2xl:px-0 mb-10 lg:mb-20 lg:gap-10 xl:gap-20"
            >
              <article>
                <h3 className="mt-5">{title}</h3>
                <div className="green-underline"></div>
                {desc.map((item, index) => (
                  <p className="mt-5 mb-10" key={index}>
                    {item.text}
                  </p>
                ))}
                <ul>
                  {list.map((item, index) => (
                    <li key={index} className="font-bold my-3">
                      {item.text}
                    </li>
                  ))}
                </ul>
              </article>

              <article>
                <img src={image} alt={title} title={title} />
              </article>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
