import { useState } from "react"
import { homepageCards } from "../data/data"

export default function HomepageCards() {
  const [cards] = useState(homepageCards)

  return (
    <>
      <section className="homepage mx-5 pb-10 2xl:mx-auto xl:max-w-6xl xl:mx-auto grid grid-cols-1 gap-5 sm:grid-cols-2 py-10 md:grid-cols-3 lg:relative">
        {cards.map(({ id, icon, desc }) => (
          <article
            key={id}
            className="article__cards homepage__cards p-5 rounded"
          >
            <img
              src={icon}
              alt={desc}
              tite={desc}
              className="w-10 mb-5 mx-auto block"
            />
            <p className="text-center">{desc}</p>
          </article>
        ))}
      </section>
    </>
  )
}
