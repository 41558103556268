import crmhero from "../../assets/money.jpg"

export default function CRMHero() {
  return (
    <>
      <section className="home">
        <div className="overlay flex items-center justify-center text-center flex-col px-5 2xl:px-0">
          <h1 className="mb-5">
            Time is money
            <span className="block">Make the most of it</span>
          </h1>
          <p className="md:w-11/12 lg:w-1/2">
            Trading Technologies's Back Office system has been designed to save
            time and effort but not on the cost of the exceptional quality it
            offers. Enjoy a convenient Back Office system developed with special
            attention to every detail in order to lessen the hassle for you and
            your team.
          </p>
        </div>
      </section>

      <article className="px-5 2xl:px-0">
        <img
          src={crmhero}
          alt="Trading Platforms"
          title="Trading Platforms"
          className="lg:w-1/2 lg:mx-auto -mt-16 lg:-mt-32"
        />
      </article>
    </>
  )
}
