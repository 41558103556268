import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import {
  ScrollToTop,
  Header,
  Home,
  Form,
  Footer,
  TradingPlatforms,
  CustomizableCRM,
  Section3,
  HomepageCards,
} from "./components"
import {
  CRM,
  TradingPlatform,
  Technologies,
  Help,
  AboutUs,
  Error,
  PrivacyPolicy,
} from "./pages"

function App() {
  return (
    <BrowserRouter>
      <main>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <React.Fragment>
                <Home />
                <TradingPlatforms />
                <CustomizableCRM />
                <Section3 />
                <HomepageCards />

                {/* Homepage form */}
                <div
                  id="contact-form"
                  className="article__cards py-10 lg:pb-20 lg:pt-14"
                  style={{ borderBottomRightRadius: "3.5rem" }}
                >
                  <h2 className="px-5 2xl:px-0 lg:text-center mb-5">
                    <span>Let us help</span> grow your business
                  </h2>
                  <Form />
                </div>
              </React.Fragment>
            }
          ></Route>
          <Route path="/backoffice" element={<CRM />}></Route>
          <Route
            path="/trading-platforms"
            element={<TradingPlatform />}
          ></Route>
          <Route path="/technologies" element={<Technologies />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
        <Footer />
      </main>
    </BrowserRouter>
  )
}

export default App
