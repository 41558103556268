import tradingPlatform from "../assets/trading-platforms-hero.jpg"
import Form from "../components/Form"
import TradingPlatformCards from "./components/TradingPlatformCards"
import Leads from "./components/Leads"
import Delight from "./components/Delight"

export default function TradingPlatform() {
  return (
    <>
      <section className="home">
        <div className="overlay flex items-center justify-center flex-col xl:max-w-6xl xl:mx-auto px-5 2xl:px-0 text-center">
          <h1 className="mb-5">
            Trade the Markets <span>with our</span> Lightning-Fast Platform
          </h1>
          <p className="mb-10 lg:w-2/3">
            Our web application serves as your one-stop destination to check
            quotes and place trades, see account balances, P&amp;L, and key
            performance metrics, funding, reporting, and more.
          </p>
        </div>
      </section>
      <article className="px-5 2xl:px-0">
        <img
          src={tradingPlatform}
          alt="Trading Platforms"
          title="Trading Platforms"
          className="lg:w-1/2 lg:mx-auto -mt-24 sm:-mt-28 md:-mt-32 xl:-mt-44"
        />
      </article>

      <TradingPlatformCards />
      <Leads />
      <Delight />

      <div
        id="contact-form"
        className="article__cards px-5 2xl:px-0 py-10 xl:py-20 mt-10 xl:mt-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <article className="xl:max-w-7xl xl:mx-auto">
          <h2 className="text-center mb-10">
            <span>Let us contact you for </span> more{" "}
            <span className="block font-bold">
              information about our offers
            </span>
          </h2>
          <Form />
        </article>
      </div>
    </>
  )
}
