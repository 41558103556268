import { useState } from "react"
import { tradingPlatformsCards } from "../../data/data"

export default function TradingPlatformCards() {
  const [cards] = useState(tradingPlatformsCards)

  return (
    <>
      <section className="flex flex-col justify-center flex-wrap sm:flex-row px-5 lg:max-w-5xl lg:mx-auto 2xl:px-0 my-10 xl:my-20">
        {cards.map(({ id, title, image }) => (
          <article
            key={id}
            className="article__cards p-5 rounded sm:w-56 h-44 m-2 lg:mr-20"
          >
            <img
              src={image}
              alt={title}
              title={title}
              className="w-16 mx-auto"
            />
            <h4 className="text-center text-base font-light mt-5">{title}</h4>
          </article>
        ))}
      </section>
    </>
  )
}
