import image from "../../assets/liquidity-providers.jpg"

export default function Liquidity() {
  return (
    <>
      <section className="px-5 xl:max-w-6xl xl:mx-auto 2xl:px-0 flex flex-col-reverse justify-center md:grid grid-cols-2 gap-10 py-10">
        <article>
          <h3>Liquidity Providers</h3>
          <div className="green-underline"></div>
          <p className="mt-5 lg:w-11/12">
            For nearly a decade we have been working with the industry’s top
            liquidity providers. Choose from the ones we offer, or provide us
            with your preferred one, and we will happily integrate it into your
            system.
          </p>
        </article>

        <article>
          <img
            src={image}
            alt="Liquidity Providers"
            title="Liquidity Providers"
            className="w-full"
          />
        </article>
      </section>
      <h5 className="font-light text-center text-xl pb-10 lg:text-2xl text-gray-200 lg:pb-20">
        &quot;Save time and free yourself for what really matters&quot;
      </h5>
    </>
  )
}
