import help from "../assets/help-hero.jpg"
import Form from "../components/Form"
import CustomerSupport from "./components/CustomerSupport"
import HelpCards from "./components/HelpCards"

export default function Help() {
  return (
    <>
      <section className="home">
        <div className="overlay flex items-center justify-center flex-col xl:max-w-5xl xl:mx-auto px-5 2xl:px-0 text-center">
          <h1 className="mb-5 leading-tight">
            Best customer <span className="block">care provider</span>
          </h1>
          <p className="mb-10 lg:w-2/3">
            Our core belief is that a "Happy customer is a customer for life"!
            That is why we strongly follow this concept and put the greatest
            effort to satisfy our client's needs with every product we provide.
          </p>
        </div>
      </section>
      <article className="px-5 2xl:px-0">
        <img
          src={help}
          alt="Best customer care provider"
          title="Best customer care provider"
          className="lg:w-1/2 lg:mx-auto -mt-36 lg:-mt-44"
        />
      </article>

      <HelpCards />
      <CustomerSupport />

      <div
        id="contact-form"
        className="article__cards px-5 2xl:px-0 py-10 xl:py-20 mt-10 xl:mt-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <article className="xl:max-w-7xl xl:mx-auto">
          <h2 className="text-center mb-10">
            Learn More About Our <span>Customer Support</span>
          </h2>
          <Form />
        </article>
      </div>
    </>
  )
}
