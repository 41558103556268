import { useState } from "react"
import { crmSlider } from "../../data/data"

export default function CRMSlides() {
  const [slides] = useState(crmSlider)
  const [value, setValue] = useState(0)

  const { image, button, links } = slides[value]

  return (
    <>
      <div
        className="light__green pt-10 pb-32 my-10 lg:pt-0 lg:pb-20"
        style={{
          borderTopLeftRadius: "3.5rem",
          borderBottomRightRadius: "3.5rem",
        }}
      >
        <section className="pt-8 pb-14 px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto flex flex-col items-center justify-center text-center lg:text-left md:grid md:grid-cols-2 md:gap-5 xl:py-20 lg:gap-20">
          <article>
            <img
              src={image}
              alt={button}
              title={button}
              className="w-full"
            />
          </article>

          <article className="mt-8 flex flex-wrap items-center justify-center md:grid grid-cols-1 gap-5">
            {slides.map((item, index) => (
              <button
                key={index}
                onClick={() => setValue(index)}
                className={`font-semibold py-2 px-6 rounded-full text-white border-2 border-white md:w-72 ${
                  index === value && "bg-white text-green-500"
                }`}
              >
                {item.button}
              </button>
            ))}
          </article>
        </section>
      </div>

      <div className="px-5 2xl:px-0 grid grid-cols-2 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:max-w-6xl xl:mx-auto -mt-40 mb-20 lg:-mt-32">
        {links.map((link, index) => (
          <article
            key={index}
            className="bg-white p-5 rounded-tl-3xl rounded-br-3xl h-28 sm:h-auto flex-center"
          >
            <p className="text-green-500 text-base text-center lg:text-xl">
              {link.text}
            </p>
          </article>
        ))}
      </div>
    </>
  )
}
