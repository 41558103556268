import { Link } from "react-router-dom"
import logo from "../assets/logo.png"

export default function Logo() {
  return (
    <>
      <Link to="/">
        <img
          src={logo}
          alt="Trading Technologies"
          title="Trading Technologies"
          className="logo w-32 lg:w-40"
        />
      </Link>
    </>
  )
}
